import { template as template_a530a8ea5e62409395bacc7415c3aa78 } from "@ember/template-compiler";
const FKControlMenuContainer = template_a530a8ea5e62409395bacc7415c3aa78(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
